import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Grid, Button, TextField} from '@material-ui/core';
import validate from 'validate.js';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}));

const schema = {
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {
            maximum: 128,
        },
    },
    email: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {
            maximum: 300,
        },
    },
    text: {
        presence: {allowEmpty: false, message: 'is required'},
    },
};

const ContactForm = (): JSX.Element => {
    const classes = useStyles();
    const {executeRecaptcha} = useGoogleReCaptcha()

    const [formState, setFormState] = React.useState<FormStateProps>({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });

    React.useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const hasError = (field: string): boolean =>
        formState.touched[field] && formState.errors[field] ? true : false;


    const handleSubmit = async (event) => {
        // event.preventDefault()
        // if (!executeRecaptcha) {
        //     return
        // }
        // const result = await executeRecaptcha('homepage')
        // fetch("https://getform.io/f/0aaf7689-e810-428b-9c2e-fba1763277b2", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //     },
        //     body: JSON.stringify({
        //         ...formState.values,
        //         "g-recaptcha-response": result
        //     }),
        // })
        //     .then(response => console.log(response))
        //     .catch(error => console.log(error))
    }

    return (
        <div className={classes.root}>
            <form
                id={"contact-form"}
                name="contact-form"
                method="post"
                action="https://getform.io/f/0aaf7689-e810-428b-9c2e-fba1763277b2"
            >
                <input type="hidden" name="form-name" value="contact-form"/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                            <strong>Contact Us</strong>
                        </Typography>
                        <Typography variant="h6" color="textSecondary" align="center">
                            We carefully read and answer to all our emails.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="Full Name"
                            label="Full Name *"
                            variant="outlined"
                            size="medium"
                            name="name"
                            fullWidth
                            helperText={
                                hasError('name') ? formState.errors.name[0] : null
                            }
                            error={hasError('name')}
                            onChange={handleChange}
                            type="text"
                            value={formState.values.name || ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            placeholder="E-mail"
                            label="E-mail *"
                            variant="outlined"
                            size="medium"
                            name="email"
                            fullWidth
                            helperText={hasError('email') ? formState.errors.email[0] : null}
                            error={hasError('email')}
                            onChange={handleChange}
                            type="email"
                            value={formState.values.email || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Message"
                            label="Message *"
                            variant="outlined"
                            name="text"
                            fullWidth
                            helperText={
                                hasError('text') ? formState.errors.text[0] : null
                            }
                            error={hasError('text')}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            value={formState.values.text || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                            Fields that are marked with * sign are required.
                        </Typography>
                        <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={!formState.isValid}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default ContactForm;
